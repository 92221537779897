import Autocomplete from "@trevoreyre/autocomplete-js";
import { define, html } from "hybrids";

var autocomplete_initialized = false;


function doAutocomplete(host) {
  const wikiUrl = "https://en.wikipedia.org";
  const params = "action=query&list=search&format=json&origin=*";

  if (!autocomplete_initialized) {
    new Autocomplete(host.divref, {
      // Search function can return a promise
      // which resolves with an array of
      // results. In this case we're using
      // the Wikipedia search API.
      search: (input) => { 

//          const url = `${wikiUrl}/w/api.php?${params}&srsearch=${encodeURI(input)}`;

        const url = `https://tovare.com/search/api/?q=${encodeURI(input)}`

        return new Promise((resolve) => {
          if (input.length < 3) {
            return resolve([]);
          }

          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              resolve(data); 
            });
        });
      },
      // Control the rendering of result items.
      // Let's show the title and snippet
      // from the Wikipedia results
      renderResult: (result, props) => `
        <li ${props}>
        <div class="wiki-title">
            ${result.title}
        </div>
        <div class="wiki-snippet">
            ${result.summary}
        </div>
        </li>
    `,

      // Wikipedia returns a format like this:
      //
      // {
      //   pageid: 12345,
      //   title: 'Article title',
      //   ...
      // }
      //
      // We want to display the title
      getResultValue: (result) => result.title,

      // Open the selected article in
      // a new window
      onSubmit: (result) => {
        window.open(result.url);
      },
    });
    autocomplete_initialized = true;
  }
}
const SearchElement = {
  value: 1,
  divref: ({ render }) => {
    const target = render();
    return target.querySelector("#autocomplete");
  },
  render: ({ value }) => html`
    <style>
      .autocomplete-input {
        border: 1px solid #eee;
        border-radius: 8px;
        width: 100%;
        padding: 12px 12px 12px 48px;
        box-sizing: border-box;
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        flex: 1;
        background-color: #eee;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: 12px;
      }
      .autocomplete-input:focus,
      .autocomplete-input[aria-expanded="true"] {
        border-color: rgba(0, 0, 0, 0.12);
        background-color: #fff;
        outline: none;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
      }
      [data-position="below"] .autocomplete-input[aria-expanded="true"] {
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0;
      }
      [data-position="above"] .autocomplete-input[aria-expanded="true"] {
        border-top-color: transparent;
        border-radius: 0 0 8px 8px;
        z-index: 2;
      }
      .autocomplete[data-loading="true"]:after {
        content: "";
        border: 3px solid rgba(0, 0, 0, 0.12);
        border-right-color: rgba(0, 0, 0, 0.48);
        border-radius: 100%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        animation: rotate 1s linear infinite;
      }
      .autocomplete-result-list {
        margin: 0;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 0;
        box-sizing: border-box;
        max-height: 296px;
        overflow-y: auto;
        background: #fff;
        list-style: none;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
      }
      [data-position="below"] .autocomplete-result-list {
        margin-top: -1px;
        border-top-color: transparent;
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px;
      }
      [data-position="above"] .autocomplete-result-list {
        margin-bottom: -1px;
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0;
        padding-top: 8px;
      }
      .autocomplete-result {
        cursor: default;
        padding: 12px 12px 12px 48px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: 12px;
      }
      .autocomplete-result:hover,
      .autocomplete-result[aria-selected="true"] {
        background-color: rgba(0, 0, 0, 0.06);
      }
      @keyframes rotate {
        0% {
          transform: translateY(-50%) rotate(0deg);
        }
        to {
          transform: translateY(-50%) rotate(359deg);
        }
      }

      .autocomplete-result {
        border-top: 1px solid #eee;
        /* padding: 16px;
        background: transparent; */
      }

      .wiki-title {
        font-size: 16px;
        margin-bottom: 8px;
        font-family: 'Orbitron', sans-serif;
        font-weight: 600;
        text-align: left;
      }

      .wiki-snippet {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-align: left;
      }
    </style>
    <div id="autocomplete" class="autocomplete">
      <input
        autofocus
        oninput="${doAutocomplete}"
        class="autocomplete-input"
        placeholder="Search"
        aria-label="Search"
      />
      <ul class="autocomplete-result-list"></ul>
    </div>
  `,
};

define("search-element", SearchElement);
